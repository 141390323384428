// Best practices grabbed from https://github.com/vercel/next.js/blob/3410106958c1d1938a0a7e50cc4896986ad5890d/examples/with-apollo/lib/apolloClient.js

import merge from "deepmerge";
import { useMemo } from "react";
import isEqual from "lodash/isEqual";
import { concatPagination } from "@apollo/client/utilities";
import {
  from,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { MUTATION_NAMES } from "util/constants";
import { identify } from "util/analytics";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

const currentUserLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (operation.operationName === MUTATION_NAMES.SIGN_IN) {
      const {
        uuid,
        email,
        dsiplayName: name,
      } = response.data.signIn.currentUser;
      identify(uuid, { email, name });
    }
    return response;
  });
});

/*
 * Official docs - https://www.apollographql.com/docs/react/api/link/introduction/
 * If you have a collection of two or more links that should always be executed in
 * serial order, this ApolloLink.from helper method is used to combine those links
 * into a single link.
 */
function additiveLink(traceId) {
  const headers = traceId ? { headers: { "X-Amzn-Trace-Id": traceId } } : {};
  return from([
    currentUserLink,
    new HttpLink({
	    uri: 'https://www.squarefoot.com/graphql', // Server URL (must be absolute)
      credentials: "include", // Additional fetch() options like `credentials` or `headers`,
      ...headers,
    }),
  ]);
}

function createApolloClient(traceId, req) {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        cookie:
          (typeof window === "undefined"
            ? req?.headers.cookie || undefined
            : undefined) || "",
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(additiveLink(traceId)),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
          },
        },
      },
    }),
  });
}

export function initializeApollo(
  { initialState, traceId, req } = {
    initialState: null,
    traceId: undefined,
    req: undefined,
  }
) {
  // This comes from the Next/Apollo example repo so I don't want to mess with it for the sake of linting
  // eslint-disable-next-line no-underscore-dangle
  const _apolloClient = apolloClient ?? createApolloClient(traceId, req);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    // This comes from the Next/Apollo example repo so I don't want to mess with it for the sake of linting
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }
  return pageProps;
}

export function useApollo(pageProps) {
  const initialState = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(
    () => initializeApollo({ initialState, traceId: pageProps?.traceId }),
    [initialState, pageProps?.traceId]
  );
  return store;
}
